import React from 'react';
import './Home_ua.css';

function Home_ua() {
  return (
<>
<div class="help-ua-header">
    <a href="/ua" target="_blank" className="language_eng"><div className="lang_eng"></div></a>
    <div class="help-ua-logo">
        <a href="https://www.nobelbee.com/" class="logo-nobelbee"></a>
        <a href="https://molodist.com/" class="logo-molodist"></a>
    </div>
    <div class="help-ua-main">
        <div></div>
        <div class="help-ua-main-text">
            <h1 class="help-ua-main-title">УКРАЇНА ПІД АТАКОЮ РОСІЇ</h1>
            <div class="help-ua-main-description">
            Ви можете допомогти українцям вижити. Зробіть пожертву в затверджені надійні фонди. 
            </div>
        </div>
        <div>
            <h3 class="help-ua-download-subtitle">Виберіть його залежно від проблеми, яку ви хочете вирішити</h3>
            <div class="help-ua-download-buttons">
                <a href="" target="_blank" class="help-ua-download-button help-ua_download-apple"></a>
                <a href="https://play.google.com/store/apps/details?id=com.nobelbee.android&hl=ru&gl=US" target="_blank" class="help-ua-download-button help-ua_download-google"></a>
                <a href="https://donate.nobelbee.com" target="_blank" class="help-ua-download-button help-ua_download-webapp"></a>
            </div>
        </div>
    </div>
</div>
<div class="help-ua-project">
    <div class="help-ua-support-text">
        <h3 class="help-ua-subtitle">Проект</h3>
        <h2 class="help-ua-title">ОПИС</h2>
        <div class="help-ua-description">Ми – команда волонтерів, яка бореться на іншому фронті за свободу та можливість жити на рідній землі. Проект має на меті допомогти зібрати кошти, щоб спрямувати їх на потреби армії, лікарів, людей, які втратили життя через вторгнення Росії та подвійний геноцид українців.</div>
    </div>
</div>
<div class="help-ua-partner">
    <h2 class="help-ua-title">НАШІ ПАРТЕНРИ</h2>
    <div class="help-ua-partner-description">Ми щиро вдячні вам за підтримку та допомогу для нашого головного партнера</div>
    <a href="https://molodist.com/" class="logo-molodist partner-logo"></a>
</div>
<div class="help-ua-support">
    <div class="help-ua-support-g1">
        <img class="support-img1" src="./image/support1.jpg"/>
        <img class="support-img2" src="./image/support2.jpg"/>
    </div>
    <div class="help-ua-support-text">
        <h3 class="help-ua-subtitle">Наша робота</h3>
        <h2 class="help-ua-title">Ми підтримуємо</h2>
        <div class="help-ua-description">Ми разом з вами святкували дні народження та урочисті події, ділили з вами найщасливіші моменти життя... Але кровопролитна війна, яку розпочала Росія проти України, забрала ці життя у багатьох. У лютому наша команда вирішила змінити проект на волонтерський. Війна і горе – звичайне явище, і ми дуже хочемо допомогти всім, хто постраждав від війни.</div>
    </div>
    <div class="help-ua-support-g2">
        <img class="support-img3" src="./image/support3.jpg"/>
        <img class="support-img4" src="./image/support4.jpg"/>
    </div>
</div>
<div className="help-ua-stand">
    <div className="help-ua-stand-g1">
    <div className="help-ua-stand-text">
        <h2 className="help-ua-title">#standwithukraine</h2>
        <div className="help-ua-description">Підтримайте нас у цій жорстокій боротьбі за життя і свободу. За можливість вільно жити вдома. Тисячі людей покинули свої домівки, рятуючи життя, ще тисячі не мають куди повернутися, тисячі зникли безвісти. Допоможіть нам вистояти.</div>
    </div>
    <img className="stand-img" src="./image/stand3.jpg"alt="war"/>
    </div>
    <div className="help-ua-stand-g2">
        <div className="img-with-girl">
            <img className="stand-img" src="./image/stand1.jpg" alt="war"/>
            <div className="sitting-girl"></div>
        </div> 
        <img className="stand-img" src="./image/stand4.jpg" alt="war"/>
        
    </div>
    <div className="help-ua-stand-g3">
        <img className="stand-img" src="./image/stand2.jpg" alt="war"/>
        <img className="stand-img" src="./image/stand5.jpg" alt="war_cry"/>
    </div>
</div>
<div class="help-ua-donate">
    <h2 class="help-ua-donate-title">ПОЖЕРТВУЙ ДЛЯ УКРАЇНИ. ЖЕРТВУЙ ЗА ЖИТТЯ</h2>
    <div>
        <h3 class="help-ua-download-subtitle">Виберіть його залежно від проблеми, яку ви хочете вирішити</h3>
        <div class="help-ua-download-buttons donate-bottom">
            <a href="" target="_blank" class="help-ua-download-button help-ua_download-apple"></a>
            <a href="https://play.google.com/store/apps/details?id=com.nobelbee.android&hl=ru&gl=US" target="_blank" class="help-ua-download-button help-ua_download-google"></a>
            <a href="https://donate.nobelbee.com" target="_blank" class="help-ua-download-button help-ua_download-webapp"></a>
        </div>
    </div>
    <div class="help-ua-footer">
        <a href="https://www.nobelbee.com/" class="logo-nobelbee"></a>
        <div class="help-ua-footer-midtext">
            <a href="">Політика файлів cookie</a>
            <span>З підтримкою</span>
        </div>
        <a href="https://molodist.com/" class="logo-molodist"></a>    
    </div>
</div>
</>
  );
};

export default Home_ua;