import React from 'react';
import './App.css';
import Home from './Home/Home.jsx'
import Router from './Routes/index.jsx';

function App() {
  return (
    <Router />
  );
}

export default App;