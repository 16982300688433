import React from 'react';
import './Home.css';

function Home() {
  return (
<>
<div class="help-ua-header">
    <a href="/ua" target="_blank" className="language_ua"><div className="lang_ua"></div></a>
    <div class="help-ua-logo">
        <a href="https://www.nobelbee.com/" class="logo-nobelbee"></a>
        <a href="https://molodist.com/" class="logo-molodist"></a>
    </div>
    <div class="help-ua-main">
        <div></div>
        <div class="help-ua-main-text">
            <h1 class="help-ua-main-title">UKRAINE IS UNDER RUSSIAN ATTACK</h1>
            <div class="help-ua-main-description">
                You can help Ukrainians to survive. Donate to approved reliable handpicked funds. 
            </div>
        </div>
        <div>
            <h3 class="help-ua-download-subtitle">Choose it based on the issue you want to address</h3>
            <div class="help-ua-download-buttons">
                <a href="" target="_blank" class="help-ua-download-button help-ua_download-apple"></a>
                <a href="https://play.google.com/store/apps/details?id=com.nobelbee.android&hl=ru&gl=US" target="_blank" class="help-ua-download-button help-ua_download-google"></a>
                <a href="https://donate.nobelbee.com" target="_blank" class="help-ua-download-button help-ua_download-webapp"></a>
            </div>
        </div>
    </div>
</div>
<div class="help-ua-project">
    <div class="help-ua-support-text">
        <h3 class="help-ua-subtitle">Project</h3>
        <h2 class="help-ua-title">DESCRIPTION</h2>
        <div class="help-ua-description">We are a team of volunteers fighting on another front for freedom and the opportunity to live in their homeland. The project aims to help raise funds to direct them to the needs of the army, doctors, people who lost their lives due to the invasion of Russia and the double genocide of Ukrainians.</div>
    </div>
</div>
<div class="help-ua-partner">
    <h2 class="help-ua-title">OUR PARTNER</h2>
    <div class="help-ua-partner-description">We sincerely thank our leading partner Kyiv International Film Festival for priceless support and help</div>
    <a href="https://molodist.com/" class="logo-molodist partner-logo"></a>
</div>
<div class="help-ua-support">
    <div class="help-ua-support-g1">
        <img class="support-img1" src="./image/support1.jpg"/>
        <img class="support-img2" src="./image/support2.jpg"/>
    </div>
    <div class="help-ua-support-text">
        <h3 class="help-ua-subtitle">Our work</h3>
        <h2 class="help-ua-title">We support</h2>
        <div class="help-ua-description">We used to celebrate birthdays and special events together with you, we shared the happiest moments in life with you... But the bloody war started by Russia against Ukraine took away these lives from many. In February, our team decided to change our project to a volunteer project. War and grief are common and we really want to help everyone affected by war.</div>
    </div>
    <div class="help-ua-support-g2">
        <img class="support-img3" src="./image/support3.jpg"/>
        <img class="support-img4" src="./image/support4.jpg"/>
    </div>
</div>
<div className="help-ua-stand">
    <div className="help-ua-stand-g1">
    <div className="help-ua-stand-text">
        <h2 className="help-ua-title">#standwithukraine</h2>
        <div className="help-ua-description">Support us in this brutal fight for life and freedom. For the opportunity to live freely at home. Thousands of people left their homes to save their lives, thousands more have nowhere to return, thousands are missing. Help us persevere.</div>
    </div>
    <img className="stand-img" src="./image/stand3.jpg"alt="war"/>
    </div>
    <div className="help-ua-stand-g2">
        <div className="img-with-girl">
            <img className="stand-img" src="./image/stand1.jpg" alt="war"/>
            <div className="sitting-girl"></div>
        </div> 
        <img className="stand-img" src="./image/stand4.jpg" alt="war"/>
        
    </div>
    <div className="help-ua-stand-g3">
        <img className="stand-img" src="./image/stand2.jpg" alt="war"/>
        <img className="stand-img" src="./image/stand5.jpg" alt="war_cry"/>
    </div>
</div>
<div class="help-ua-donate">
    <h2 class="help-ua-donate-title">DONATE FOR UKRAINE. DONATE FOR LIFE</h2>
    <div>
        <h3 class="help-ua-download-subtitle">Choose it based on the issue you want to address</h3>
        <div class="help-ua-download-buttons donate-bottom">
            <a href="" target="_blank" class="help-ua-download-button help-ua_download-apple"></a>
            <a href="https://play.google.com/store/apps/details?id=com.nobelbee.android&hl=ru&gl=US" target="_blank" class="help-ua-download-button help-ua_download-google"></a>
            <a href="https://donate.nobelbee.com" target="_blank" class="help-ua-download-button help-ua_download-webapp"></a>
        </div>
    </div>
    <div class="help-ua-footer">
        <a href="https://www.nobelbee.com/" class="logo-nobelbee"></a>
        <div class="help-ua-footer-midtext">
            <a href="">Cookie policy</a>
            <span>With support</span>
        </div>
        <a href="https://molodist.com/" class="logo-molodist"></a>    
    </div>
</div>
</>
  );
};

export default Home;